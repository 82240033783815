import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/App/Layout";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import TreatmentDetailsContent from "../components/Treatments/TreatmentDetailsContent";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { useIntl } from "gatsby-plugin-react-intl";

const Treatment = ({ data }) => {
  const intl = useIntl();

  const {
    allContentfulTreatment: { nodes },
    allContentfulProduct: { nodes: products },
  } = data;
  const treatment = { ...nodes[0] };
  const treatmentName =
    intl.locale === "en"
      ? treatment?.name
      : treatment?.name_fr || treatment?.name;
  // console.log("Treatments", treatment);
  const treatmentImage =
    intl.locale === "en"
      ? treatment?.image?.fixed?.src
      : treatment?.imageFr?.fixed?.src || treatment?.image?.fixed?.src;

  const treatmentDescription =
    intl.locale === "en"
      ? documentToPlainTextString(JSON.parse(treatment?.description.raw))
      : documentToPlainTextString(JSON.parse(treatment?.description_fr.raw)) ||
        documentToPlainTextString(JSON.parse(treatment?.description.raw));
  const canonical = `${process.env.GATSBY_MAIN_URL}/${
    intl.locale === "en" ? "treatments" : "fr/treatments"
  }/${treatment?.slug}`;

  return (
    <Layout
      title={treatmentName}
      description={treatmentName + treatmentDescription}
      language={intl.locale}
      canonical={canonical}
      image={treatmentImage}
    >
      <NavbarTwo />
      <TreatmentDetailsContent treatment={treatment} products={products} />
      <Footer />
    </Layout>
  );
};

export const querya = graphql`
  query GetSingleTreatment($slug: String) {
    allContentfulTreatment(filter: { slug: { eq: $slug } }) {
      nodes {
        name
        name_fr
        description {
          raw
        }
        description_fr {
          raw
        }
        slug
        image {
          fluid(quality: 50) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        imageFr {
          fluid(quality: 50) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }

    allContentfulProduct(
      filter: { treatment: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        treatment_desc {
          treatment_desc
        }
        treatment_desc_fr {
          treatment_desc_fr
        }
        slug
        name
        nameFr
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        imageFr {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default Treatment;
