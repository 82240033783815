import React from "react";
// import { Link } from "gatsby";
import Image from "gatsby-image";
import starIcon from "../../assets/images/star-icon.png";
import PageBanner from "../Common/PageBanner";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const TreatmentDetailsContent = (props) => {
  const intl = useIntl();
  const { treatment, products } = props;
  const treatmentName =
    intl.locale === "en"
      ? treatment?.name
      : treatment?.name_fr || treatment?.name;
  const treatmentImage =
    intl.locale === "en"
      ? treatment?.image?.fluid
      : treatment?.imageFr?.fluid || treatment?.image?.fluid;
  const treatmentDescription =
    intl.locale === "en"
      ? renderRichText(treatment?.description)
      : renderRichText(treatment?.description_fr) ||
        renderRichText(treatment?.description);
  return (
    <div className="courses-details-area">
      <div className="courses-details-image">
        <Image fluid={treatmentImage} alt={treatmentName} />
      </div>
      <PageBanner
        pageTitle={treatmentName}
        homePageText={intl.formatMessage({ id: "home" })}
        homePageUrl="/"
        treatMentPageText={intl.formatMessage({ id: "treatments" })}
        treatMentPageUrl="/treatments"
        activePageText={treatmentName}
      />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="courses-details-desc">{treatmentDescription}</div>
            <div className="courses-details-desc">
              <section className="process-area pt-100 pb-70">
                <div className="container">
                  <div className="section-title">
                    <h2 className="sub-title">
                      <img src={starIcon} alt="banner" />{" "}
                      {intl.formatMessage({ id: "related_products" })}
                    </h2>
                  </div>

                  <div className="row">
                    {products.map((product) => {
                      const productName =
                        intl.locale === "en"
                          ? product?.name
                          : product?.nameFr || product?.name;
                      const productImage =
                        intl.locale === "en"
                          ? product?.image?.fluid
                          : product?.imageFr?.fluid || product?.image?.fluid;
                      return (
                        <>
                          <div className="col-lg-6 col-md-6">
                            <div className="single-process-box">
                              <Link
                                to={`/${
                                  intl.locale === "en" ? "product" : "produit"
                                }/${product.slug}`}
                              >
                                <div className="image">
                                  <Image
                                    style={{ width: "60%" }}
                                    fluid={productImage}
                                    alt={productName}
                                  />
                                </div>
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="single-process-box product-desc">
                              <h2>{productName}</h2>
                              {intl.locale === "en"
                                ? product?.treatment_desc?.treatment_desc
                                : product?.treatment_desc_fr
                                    ?.treatment_desc_fr ||
                                  product?.treatment_desc?.treatment_desc}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentDetailsContent;
